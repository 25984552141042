import React from 'react'

import XboxSlim from '../images/xboxslim-removebg.png'
import XboxDash from '../images/xboxdash.png'
import Xbox from '../images/oldxbox360-removebg.png'
const Info = () => {

  const highlightText = (word) => {
    if (word.toLowerCase().includes('xbox')) {
      return <span className="text-lime-300">{word}</span>;
    } else if (word === '360') {
      return <span className="text-gray-400">{word}</span>;
    }
    return word;
  };

  const renderHighlightedText = (text) => {
    const words = text.split(' ');
    return words.map((word, index) => (
      <React.Fragment key={index}>
        {index > 0 && ' '}
        {highlightText(word)}
      </React.Fragment>
    ));
  };

  return (    
    <div>
              <div className='flex flex-col justify-around items-center md:flex-row md:items-center w-screen mt-14'>                        
          <div className='md:ml-36 mb-4 md:mb-0'>                          
            <img src={Xbox} alt="img" className='md:w-auto' />
        </div>
          <p className='w-80 md:w-96 text-xl'>
            In 2005, the {renderHighlightedText('XBOX 360')} was released, ushering in a new era of 
            gaming. Gamers were captivated by its powerful hardware and 
            vibrant library of games. Fond memories include late-night 
            Halo sessions, exploring Xbox Live, and the excitement of a
            groundbreaking console that defined a generation.
          </p>  
          
        </div>                 

    <div className='rounded-div flex items-center justify-center'>
<div className='flex flex-col justify-center items-center'> 
<img src={XboxSlim} alt="img" className='w-56' />       
  <p className='text-center'>
   ${renderHighlightedText('XBOX 360')} coin has no association with Microsoft.
  ${renderHighlightedText('XBOX 360')} is a meme coin with no intrinsic value or expectation of financial return. There is no formal team or roadmap. the coin is completely useless and for entertainment purposes only.
  </p>
  <img src={XboxDash} alt="img" className='rounded-3xl mt-10' />
</div>
</div>
</div>
    
  )
}

export default Info


