import React, {useEffect} from 'react'
import PhantomLogo from '../images/phantomlogo.png'



const Buy = () => {
    useEffect(() => {
        // Load the Jupiter Terminal script dynamically
        const script = document.createElement('script');
        script.src = 'https://terminal.jup.ag/main-v2.js';
        script.async = true;
    
        script.onload = () => {
          // Once the script is loaded, initialize Jupiter
          window.Jupiter.init({
            displayMode: 'integrated',
            integratedTargetId: 'integrated-terminal',
            endpoint: 'https://api.mainnet-beta.solana.com',
            strictTokenList: false,
            formProps: {
              fixedOutputMint: true,
              initialOutputMint: 'GLicyWo6zTz5JjuTtN6Hsr5fDVYP6NWJ3m8wHjK4Th4R',
            },
          });
        };
    
        // Append the script to the document body
        document.head.appendChild(script);
    
        // Clean up the script when the component unmounts
        return () => {
          document.head.removeChild(script);
        };
      }, []);
      
  return (
    <section class='sticky'>
    <div className='buysection h-96 flex flex-col items-center md:flex-row md:items-center w-screen m-auto justify-evenly'>    

    <div className='flex flex-col mb-10'>
    <ul className='flex flex-col m-auto items-center'>
    <h1 id='howToBuy' className='flex justify-center text-4xl mb-5 font-thin'>How To Buy:</h1> 
                <li className='flex justify-center items-center'>1. Install the Phantom Wallet <img src={PhantomLogo} alt="img" className='w-7 rounded-xl ml-2' /></li>
                <li>2. Acquire Solana (SOL)</li>
                <li>3. Go to Raydium or Jupiter and paste in our contract</li>                
                
            </ul>  
    </div>
    <div>
    <div id="integrated-terminal">
      {/* The div for Terminal to render */}
    </div>
    </div>
    <div class="bubbles">
          <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        
      </div>  
    </div>
    </section>
  )
}

export default Buy