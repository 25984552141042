import React from 'react';
import { Menu } from '@headlessui/react';
import { Link } from 'react-router-dom';




import Xbox360Logo from '../images/xbox2005LogoNoBG.png';

import Mic from '../images/360mic-removebg.png'
import TelegramLogo from '../images/telegram.png'


const About = () => {
  const highlightText = (word) => {
    if (word.toLowerCase().includes('xbox')) {
      return <span className="text-lime-300">{word}</span>;
    } else if (word === '360') {
      return <span className="text-gray-400">{word}</span>;
    } 
    return word;
  };

  const renderHighlightedText = (text) => {
    const words = text.split(' ');
    return words.map((word, index) => (
      <React.Fragment key={index}>
        {index > 0 && ' '}
        {highlightText(word)}
      </React.Fragment>
    ));
  };

  return (
    <div className="rounded-div mb-20">
      <div className="flex flex-col items-center mt-20">        
        <div className="spinning-container">
          <img
            src={Xbox360Logo}
            alt="img"
            id="RobinLogo"
            className="w-56 mt-5 spinning-image"
          />
        </div>
      </div>

      <div className="flex flex-col items-center">
        <p className="mt-10 text-5xl w-96 text-center font-thin">
          {renderHighlightedText('XBOX 360')}
        </p>
        <Menu>
          <Menu.Button className="inline-flex border justify-center mt-5 rounded border-gray-300 px-2 py-1 shadow-sm transition-all duration-300 hover:bg-white hover:bg-opacity-25">
            <Link to="https://solscan.io/token/GLicyWo6zTz5JjuTtN6Hsr5fDVYP6NWJ3m8wHjK4Th4R">
              {renderHighlightedText('Contract address')}
            </Link>
          </Menu.Button>
        </Menu>

        <div className='rounded-div bgimgdash flex flex-col justify-center items-center mt-10'>          
            <h1 className='text-5xl font-bold'>Tokenomics:</h1>        
          <ul className='mt-5 text-center text-3xl font-thin'>
            <li>Supply: 360,000,000</li>
            <li>Contract Revoked</li>
            <li>LP Burned</li>            
          </ul>
          <p>{renderHighlightedText('Xbox 360 Sent you invite to Xbox Live Party:')}</p>          
          <a href="https://t.me/+bUD7ry6PT8ZmODk0" className='mt-3'><img src={TelegramLogo} alt="img" className='w-10' /></a> 
        </div>
      </div>
      
    </div>
  );
};

export default About;
