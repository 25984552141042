
import React from 'react';


import Navbar from './components/Navbar';
import About from './components/About';
import Info from './components/Info';
import Footer from './components/Footer';
import Buy from './components/Buy';



function App() {
  
  
  return (
     <>
     
      <Navbar  />
     <About />
     <Buy />
     <Info />
     <Footer />

    
     </>
    
    
  );
}

export default App;
